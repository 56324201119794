var vm = new Vue({
  el: "#registerValidate",
  data: {
    firstName: "",
    lastName: "",
    email: "",
    checkValidate: false,
    phone: "",
    password: "",
    agreement: false,
    // business form fields
    clioUserId: "",
    businessName: "",
    contactPersonFirstName: "",
    contactPersonLastName: "",
    contactPersonPhoneNumber: "",
    contactPersonEmail: "",
    contactPersonPassword: "",
    form: {
      valid: false,
      email: { hasError: false, error: null },
      phone: { hasError: false, error: null },
      lastName: { hasError: false, error: null },
      password: { hasError: false, error: null },
      firstName: { hasError: false, error: null },
      agreement: { hasError: false, error: null },
    },
    businessForm: {
      valid: false,
      businessName: { hasError: false, error: null },
      contactPersonFirstName: { hasError: false, error: null },
      contactPersonLastName: { hasError: false, error: null },
      contactPersonPhoneNumber: { hasError: false, error: null },
      contactPersonEmail: { hasError: false, error: null },
      contactPersonPassword: { hasError: false, error: null },
      agreement: { hasError: false, error: null },
    }
  },
  mounted: function() {
    const url = new URL(window.location);

    if (url.searchParams.get('fromSigner')) {
      $("#create-individual").prop('checked', true);
      $("#individual").addClass('active');
      $("#individual").removeClass('fade');

      var signerEmail = url.searchParams.get('email');
      var signerFullname = url.searchParams.get('name');
      var signerContactNumber = url.searchParams.get('contact');
      var names = signerFullname.split(' ');

      $('#account-type-options').addClass('hidden');
      $('#social-signin').addClass('hidden');
      $('#signer-message').removeClass('hidden');
      $('#select-account-message').addClass('hidden');

      setTimeout(() => {
        this.lastName = names.pop();
        this.firstName = names.join(' ');
        this.phone = signerContactNumber;
        this.email = signerEmail;
      }, 10);
    }
  },
  watch: {
    // business account fields
    businessName(newVal) {
      this.formFieldValidator("businessName", newVal, "businessForm");
    },
    contactPersonEmail(newVal) {
      this.formFieldValidator("contactPersonEmail", newVal, "businessForm");
    },
    contactPersonLastName(newVal) {
      this.formFieldValidator("contactPersonLastName", newVal, "businessForm");
    },
    contactPersonPassword(newVal) {
      this.formFieldValidator("contactPersonPassword", newVal, "businessForm");
    },
    contactPersonFirstName(newVal) {
      this.formFieldValidator("contactPersonFirstName", newVal, "businessForm");
    },
    contactPersonPhoneNumber(newVal) {
      this.formFieldValidator("contactPersonPhoneNumber", newVal, "businessForm");

      let result = this.formatPhoneNumber(newVal);

      if (result != null) {
        this.contactPersonPhoneNumber = result;
      }
    },

    firstName(newVal) {
      this.formFieldValidator("firstName", newVal);
    },
    lastName(newVal) {
      this.formFieldValidator("lastName", newVal);
    },
    email(newVal) {
      this.formFieldValidator("email", newVal);
    },
    password(newVal) {
      this.formFieldValidator("password", newVal);
    },
    phone(newVal) {
      this.formFieldValidator("phone", newVal);
      let result = this.formatPhoneNumber(newVal);

      if (result != null) {
        this.phone = result;
      }
    },
  },
  methods: {
    // business form validation
    businessCheckAgreement($event) {
      this.agreement = $event.target.checked;
      this.businessForm['agreement'].hasError = !$event.target.checked;
      this.validateForm(this.businessForm, "businessForm");
    },
    // individual form validation
    checkAgreement($event) {
      this.agreement = $event.target.checked;
      this.form['agreement'].hasError = !$event.target.checked;
      this.validateForm(this.form);
    },
    formFieldValidator(fieldName, fieldValue, formName = "form") {
      // firstName & lastName
      if (
        fieldName === "firstName" ||
        fieldName === "lastName" ||
        fieldName === "contactPersonFirstName" ||
        fieldName === "contactPersonLastName"
      ) {
        const letters = /^[A-Za-z]+$/;

        if (!fieldValue.charAt(0).match(letters)) {
          this[formName][fieldName] = this.fieldError("* Invalid Name");
        } else if (fieldValue.length < 2) {
          this[formName][fieldName] = this.fieldError("* Please fill in atleast 2 characters");
        } else {
          this[formName][fieldName] = { hasError: false };
        }
      }

      if (fieldName === "businessName") {
        const letters = /^[A-Za-z]+$/;

        if (!fieldValue.charAt(0).match(letters)) {
          this[formName][fieldName] = this.fieldError("* Invalid Business Name");
        } else if (fieldValue.length < 2) {
          this[formName][fieldName] = this.fieldError("* Please fill in atleast 2 characters");
        } else {
          this[formName][fieldName] = { hasError: false };
        }
      }

      // email validation
      if (
        fieldName === "email" ||
        fieldName === "contactPersonEmail"
      ) {
        const emailFormat = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

        if (!fieldValue.match(emailFormat)) {
          this[formName][fieldName] = this.fieldError("* Invalid Email Address");
        } else {
          this[formName][fieldName] = { hasError: false };
        }
      }

      // password validator
      if (
        fieldName === "password" ||
        fieldName === "contactPersonPassword"
      ) {
        this[formName][fieldName] = fieldValue.length >= 6 ? { hasError: false } : this.fieldError("Please have atleast 6 characters");
      }

      // phone number
      if (
        fieldName === "phone" ||
        fieldName === "contactPersonPhoneNumber"
      ) {
        var phoneNumberFormat = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        this[formName][fieldName] = fieldValue.match(phoneNumberFormat) ? { hasError: false } : this.fieldError("* Invalid Phone Number");
      }

      this.validateForm(this[formName], formName);
    },
    fieldError(errorMessage) {
      return {
        hasError: true,
        error: errorMessage,
      };
    },
    validateForm(form, formName = "form") {
      let formValid = true;
      const { valid, ...formFields } = form;

      for (const prop in formFields) {
        if (formFields[prop].hasError || !this[prop]) {
          formValid = false;
        }
      }

      this[formName].valid = formValid;
    },
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
  },
});
